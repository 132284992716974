import React from "react";
import Heading from "./Heading";
import CallToAction from "./CallToAction";

const Copy = ({
  strapline,
  heading,
  copy,
  align = `left`,
  children,
  ctaLabel,
  ctaLink,
  ctaLevel,
  ctaSpacingBottom = `none`,
}) => (
  <>
    {heading &&
      <Heading
        align={align}
        strapline={strapline}
      >
        {heading.replace(/\s([^\s]+)$/,`\xa0$1`)}
      </Heading>
    }

    {copy}

    {ctaLabel && ctaLink &&
      <CallToAction
        anchorClassName='pageblockcta' // Class to make the link identifiable in Google Tag Manager
        align={align}
        spacingBottom={ctaSpacingBottom}
        link={ctaLink}
        level={ctaLevel}
        caption={ctaLabel}
      />
    }

    {children}
  </>
);

export default Copy;
